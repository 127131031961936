<template>
  <b-row>
    <b-col cols="12">
      <h1>Reporting Data [Excel]</h1>
      <hr />
    </b-col>
    <b-col md="12" cols="12">
      <b-card body-class="p-0">
        <div class="p-2" align-h="between" align-v="center">
          <h2>Monitoring Kendaraan Jalur Darat</h2>
          <ReportingT1 />
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard, BCardFooter, BCardText } from 'bootstrap-vue'
import ReportingT1 from './ReportingT1.vue'

export default {
  components: {
    ReportingT1,
    BCard,
    BCardFooter,
    BCardText,
    BRow,
    BCol
  }
}
</script>

<style></style>
