var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h1', [_vm._v("Reporting Data [Excel]")]), _c('hr')]), _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "body-class": "p-0"
    }
  }, [_c('div', {
    staticClass: "p-2",
    attrs: {
      "align-h": "between",
      "align-v": "center"
    }
  }, [_c('h2', [_vm._v("Monitoring Kendaraan Jalur Darat")]), _c('ReportingT1')], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }